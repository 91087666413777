export enum ActivityUnits {
  Percent = '%',
  Count = '#',
  Dollar = '$',
}
export interface Option {
  id: number
  name: string
}

export const formatQuantity = (
  quantity: number,
  quantityType: string,
): string => {
  const currency = Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  })
  if (quantityType === ActivityUnits.Percent) {
    return `${quantity}%`
  } else if (quantityType === ActivityUnits.Dollar) {
    return `${currency.format(quantity)}`
  } else {
    return `${quantity}`
  }
}
