import React, { useRef, useState } from 'react';
import { InputGroup, InputRightElement, InputLeftElement, Menu, MenuList, MenuItem } from '@chakra-ui/react';
import moment from 'moment';
// import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons'
import { ICON_BY_NAME } from 'ui/components/Icons/Icon';
import Calendar from '../Icons/Calendar';
import { CbInput } from '../Inputs/CbInput';
import { DateRange } from './CbDateRangePicker';
import _ from 'lodash';
const RightIcon = ({
  Icon,
  onClick,
  iconColor
}) => <InputRightElement children={<Icon color={iconColor} width="12px" height="12px" zIndex="0" opacity={0.6} _hover={{
  opacity: 1
}} />} onClick={onClick} position={'relative'} right={8} sx={{
  h: 'var(--chakra-sizes-6)',
  w: 'var(--chakra-sizes-6)',
  _disabled: {
    cursor: 'not-allowed'
  },
  borderRadius: '50%'
}} data-sentry-element="InputRightElement" data-sentry-component="RightIcon" data-sentry-source-file="CbDateRangePickerTarget.tsx" />;
interface CbDateRangePickerTargetProps {
  dateFormat?: string;
  endDate?: Date;
  handleChange: (DateRange) => void;
  iconColor?: string;
  onClick?: (e: any) => void;
  onFocus?: (e: any) => void;
  showOptions?: boolean;
  startDate?: Date;
  targetIcon?: any;
  targetProps?: any;
  variant?: string;
  isDisabled?: boolean;
  onClear?: () => void;
}
const MENU_OPTIONS = [{
  label: 'Month to Date',
  value: [moment().startOf('month').toDate(), moment().toDate()]
}, {
  label: 'Last 7 Days',
  value: [moment().subtract(7, 'days').toDate(), moment().toDate()]
}, {
  label: 'Past 30 Days',
  value: [moment().subtract(30, 'days').toDate(), moment().toDate()]
}, {
  label: 'Past 60 Days',
  value: [moment().subtract(60, 'days').toDate(), moment().toDate()]
}, {
  label: 'Past 90 Days',
  value: [moment().subtract(90, 'days').toDate(), moment().toDate()]
}] as {
  label: string;
  value: DateRange;
}[];
const CbDateRangePickerTarget = React.forwardRef((props: CbDateRangePickerTargetProps, ref: React.Ref<any>) => {
  const {
    dateFormat = 'MMM D',
    endDate,
    handleChange,
    iconColor,
    onClick,
    onFocus,
    showOptions,
    startDate,
    targetIcon,
    targetProps,
    variant,
    isDisabled,
    onClear
  } = props;
  const inputProps = _.omit(props, ['dateFormat', 'endDate', 'handleChange', 'iconColor', 'showOptions', 'startDate', 'targetIcon', 'onClear']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const formatDateRange = (): string | null => {
    const startFormatted = moment(startDate).format(dateFormat);
    const endFormatted = moment(endDate).format(dateFormat);
    if (startDate && endDate) return `${startFormatted} - ${endFormatted}`;
    if (startDate) return startFormatted;
    return '';
  };
  const handleClick = e => {
    if (isDisabled) return;
    if (showOptions) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      onClick && onClick(e);
      onFocus && onFocus(e);
    }
  };
  const isClearable = !!startDate;
  return <div ref={ref}>
        <InputGroup cursor="pointer" display="flex" alignItems={'center'} justifyContent={'center'}>
          <CbInput borderRadius={50} cursor="pointer" fontWeight="600" paddingLeft={10} paddingRight={8} placeholder={targetProps?.placeholder || 'Date Range'} readOnly ref={inputRef} value={formatDateRange()} variant={variant} {...inputProps} {...targetProps} disabled={isDisabled} onClick={handleClick} onFocus={handleClick} />
          <InputLeftElement sx={{
        h: 'var(--chakra-sizes-10)',
        w: 'var(--chakra-sizes-10)'
      }} children={targetIcon || <Calendar color={iconColor} marginLeft={4} zIndex="0" />} />
          <RightIcon Icon={isClearable ? ICON_BY_NAME.close : ICON_BY_NAME.chevrondown} onClick={isClearable ? onClear : handleClick} iconColor={iconColor} />
        </InputGroup>
        <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
          <MenuList style={{
        marginTop: '48px',
        width: '264px',
        zIndex: 9999
      }}>
            {MENU_OPTIONS.map(option => {
          return <MenuItem key={option.label} onClick={() => {
            handleChange(option.value);
            setIsMenuOpen(false);
          }}>
                  {option.label}
                </MenuItem>;
        })}
            <MenuItem onClick={onClick}>Custom</MenuItem>
          </MenuList>
        </Menu>
      </div>;
});
export default CbDateRangePickerTarget;