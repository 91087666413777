import { arcadeApiClient } from '../apiClients'
import { AuthContext } from '../providers'
import { useContext } from 'react'
import useSWR from 'swr'

const integrationsFetcher = async (url: string) => {
  return await arcadeApiClient.get(url).then(res => {
    return res.data?.status
  })
}
const entityFetcher = async (url: string) => {
  return await arcadeApiClient.get(url).then(res => {
    return res.data.entity.campaignNotificationSettings
  })
}

const integrationFetcher = async (url: string) => {
  return await arcadeApiClient.get(url).then(res => {
    return res.data.integration
  })
}

export const useIntegrations = (entityId?: number) => {
  const { auth } = useContext(AuthContext)

  const { data: slackStatus } = useSWR(
    '/multi_platform/manage/slack/registration_status',
    integrationsFetcher,
  )

  const { data: groupMeStatus } = useSWR(
    '/multi_platform/manage/group_me/registration_status',
    integrationsFetcher,
  )

  const { data: entityNotificationSettings, mutate } = useSWR(
    `multi_platform/manage/entities/${auth.entityId}`,
    entityFetcher,
  )

  const { data: dataIntegrationSource } = useSWR(
    `/multi_platform/manage/integrations/for_entity`,
    integrationFetcher,
  )

  return {
    slackStatus,
    groupMeStatus,
    entityNotificationSettings,
    dataIntegrationSource,
    mutate,
  }
}
