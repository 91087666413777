import { Icon } from '@chakra-ui/icon';
const GlobalFeed = props => <Icon {...props} data-sentry-element="Icon" data-sentry-component="GlobalFeed" data-sentry-source-file="GlobalFeed.tsx">
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="GlobalFeed.tsx">
      <g clipPath="url(#clip0_4619_59165)" data-sentry-element="g" data-sentry-source-file="GlobalFeed.tsx">
        <path d="M9 8H15V15H9V8Z" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
        <path d="M11 10H13" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
        <path d="M11 12H13" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
        <path d="M6.61501 13C5.49545 12.9148 4.42161 12.5251 3.51247 11.874C2.60332 11.2229 1.89436 10.3359 1.46413 9.31116C1.03391 8.28643 0.89921 7.16403 1.07497 6.06831C1.25073 4.9726 1.73008 3.94634 2.45992 3.10325C3.18975 2.26016 4.14157 1.63316 5.20993 1.2917C6.27829 0.950242 7.42147 0.907667 8.51281 1.16869C9.60415 1.42972 10.6011 1.98415 11.3931 2.77056C12.1851 3.55697 12.7412 4.54466 13 5.6242" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
        <path d="M4.25012 12V9.68968C4.25012 9.68968 4.7975 9.45351 4.93909 9.15163C4.98264 9.059 5.00333 8.9582 4.99957 8.85688C4.99581 8.75555 4.9677 8.65637 4.91739 8.56686L4.23439 7.35266C4.16026 7.2215 4.04196 7.11752 3.89827 7.05725C3.75457 6.99698 3.59372 6.98386 3.44125 7.01997L1.5 7.5" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
        <path d="M4 2.2054V4.07581C3.99963 4.21327 4.05911 4.34686 4.16898 4.45529C4.27884 4.56372 4.43275 4.64073 4.60616 4.67403L5.77539 4.98166C5.89346 5.00434 6.01671 5.00601 6.13574 4.98653C6.25478 4.96705 6.36646 4.92693 6.4623 4.86925C6.55814 4.81156 6.6356 4.73782 6.68879 4.65363C6.74198 4.56944 6.76949 4.47704 6.76923 4.38344V3.73599L9.33169 3.41132C9.51842 3.38735 9.68805 3.31323 9.81042 3.20214C9.93279 3.09104 9.99996 2.95017 10 2.80458V2" stroke={props?.color || 'white'} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="GlobalFeed.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="GlobalFeed.tsx">
        <clipPath id="clip0_4619_59165" data-sentry-element="clipPath" data-sentry-source-file="GlobalFeed.tsx">
          <rect width="24" height="24" fill={props?.color || 'white'} data-sentry-element="rect" data-sentry-source-file="GlobalFeed.tsx" />
        </clipPath>
      </defs>
    </svg>
  </Icon>;
GlobalFeed.displayName = 'GlobalFeed';
export default GlobalFeed;