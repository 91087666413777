import { FC, useMemo } from 'react';
import { Box, Flex, Text, CircularProgress, CircularProgressLabel, useBreakpointValue } from '@chakra-ui/react';
import { ICON_BY_NAME } from 'ui/components/Icons/Icon';
export interface CbThresholdBannerProps {
  progress: number; // Current progress (total_score)
  goal: number; // Threshold goal
  metricName: string;
  variant?: 'kirby' | 'chonky' | 'outline';
}
const backgroundColors = {
  kirby: 'gradient.sunset',
  chonky: 'gradient.localTide',
  outline: 'luigisPants.600'
};
const borders = {
  kirby: 'none',
  chonky: 'none',
  outline: '1px solid var(--chakra-colors-cloud-800)'
};
const progressColors = {
  track: {
    kirby: 'kirby.600',
    chonky: 'luigisPants.600',
    outline: 'luigisPants.400'
  },
  color: {
    kirby: 'kirby.200',
    chonky: 'luigisPants.100',
    outline: 'luigisPants.200'
  }
};
export const CbThresholdBanner: FC<CbThresholdBannerProps> = ({
  progress = 0,
  goal = 0,
  metricName,
  variant = 'kirby'
}) => {
  const percentage = useMemo(() => goal > 0 ? Math.min(progress / goal * 100, 100) : 0, [progress, goal]);
  const circularProgressSize = useBreakpointValue({
    base: 12,
    xs: 10
  });
  const isThresholdMet = percentage >= 100;
  const icon = isThresholdMet ? <ICON_BY_NAME.unlock color="white" mr={2} fontSize="sm" aria-label="Game Unlocked" /> : <ICON_BY_NAME.lock color="white" mr={2} fontSize="sm" aria-label="Threshold not met" />;
  const thresholdMessage = isThresholdMet ? 'You are now eligible to earn rewards!' : <>Complete the goal to unlock this game!</>;
  return <Box borderRadius="xl" border={borders[variant]} background={backgroundColors[variant]} padding={4} boxShadow="0px 4px 21px 3px rgba(0, 0, 0, 0.2)" color="white" data-sentry-element="Box" data-sentry-component="CbThresholdBanner" data-sentry-source-file="CbThresholdBanner.tsx">
      <Flex direction={{
      base: 'column',
      lg: 'row'
    }} justifyContent="space-between" alignItems="center" gap={2} data-sentry-element="Flex" data-sentry-source-file="CbThresholdBanner.tsx">
        {/* Section 1 */}
        <Flex direction="column" alignItems={{
        base: 'center',
        lg: 'flex-start'
      }} textAlign={{
        base: 'center',
        lg: 'left'
      }} flex={{
        base: 1,
        lg: '0 1 256px'
      }} gap={1} data-sentry-element="Flex" data-sentry-source-file="CbThresholdBanner.tsx">
          <Flex direction="row" align="center" data-sentry-element="Flex" data-sentry-source-file="CbThresholdBanner.tsx">
            {icon}
            <Text fontSize="sm" fontWeight="bold" textTransform="uppercase" data-sentry-element="Text" data-sentry-source-file="CbThresholdBanner.tsx">
              {isThresholdMet ? 'Game Unlocked' : 'Threshold not met'}
            </Text>
          </Flex>
          <Text fontSize="xs" lineHeight="1.2" maxWidth={{
          base: '100%',
          lg: '400px'
        }} data-sentry-element="Text" data-sentry-source-file="CbThresholdBanner.tsx">
            {thresholdMessage}
          </Text>
        </Flex>

        {/* Section 2 */}
        <Flex alignItems="center" justifyContent="flex-end" gap={4} direction={{
        base: 'row-reverse',
        lg: 'row'
      }} flex={{
        base: 1,
        lg: '0 1 256px'
      }} data-sentry-element="Flex" data-sentry-source-file="CbThresholdBanner.tsx">
          <Flex direction="column" alignItems={{
          base: 'flex-start',
          lg: 'flex-end'
        }} textAlign={{
          base: 'left',
          lg: 'right'
        }} data-sentry-element="Flex" data-sentry-source-file="CbThresholdBanner.tsx">
            <Box data-sentry-element="Box" data-sentry-source-file="CbThresholdBanner.tsx">
              <Text fontSize="lg" fontWeight="bold" letterSpacing="4px" data-sentry-element="Text" data-sentry-source-file="CbThresholdBanner.tsx">
                {progress}/
                <Text as="span" fontSize="sm" data-sentry-element="Text" data-sentry-source-file="CbThresholdBanner.tsx">
                  {goal}
                </Text>
              </Text>
            </Box>
            <Text fontSize="xs" fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="CbThresholdBanner.tsx">
              {metricName}
            </Text>
          </Flex>
          <CircularProgress value={percentage} size={circularProgressSize} color={progressColors.color[variant]} trackColor={progressColors.track[variant]} capIsRound data-sentry-element="CircularProgress" data-sentry-source-file="CbThresholdBanner.tsx">
            <CircularProgressLabel fontSize="xs" data-sentry-element="CircularProgressLabel" data-sentry-source-file="CbThresholdBanner.tsx">
              {`${Math.round(percentage)}%`}
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>
      </Flex>
    </Box>;
};