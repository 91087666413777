import { FC, Fragment, StrictMode, useContext, useEffect } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Gleap from 'gleap';
import mixpanel from 'mixpanel-browser';
import { normalizeUrlAndExtractParams } from 'helpers/mixpanel';
import { SWRConfig } from 'swr';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/react';
import { CbFallbackUI } from 'ui/components/CbErrorBoundary';
import { maybeReloadOnNavigate, mergeAuth, monkeyPatchXMLHttpRequest } from 'utils';
import '@workplacearcade/styles/typography.css';
import { MPThemeContextProvider, SpectatorViewProvider } from '../providers';
import { AppContext, MPAppContextProvider } from '../providers/MPAppContextProvider';
import { setupSentry } from '../helpers/setupSentry';
import { useRouter } from 'next/router';
import { usePathname, useSearchParams } from 'next/navigation';
import { Routes } from '../types';
import { FeaturesProvider, RoleProvider } from 'providers';
import { AUTH_STORAGE_KEY } from 'ui/types';
import Script from 'next/script';
declare global {
  interface Window {
    mergeAuth: typeof mergeAuth;
    prerenderReady: boolean;
    Tooltip: any;
    Appcues: any;
    AppcuesSettings: {
      enableURLDetection: boolean;
    };
  }
}
const MPAuthProvider = dynamic(async () => await import('../providers/MPAuthContextProvider'), {
  ssr: false
});
const MainLayout = dynamic(async () => await import('../components/MainLayout/MainLayout'), {
  ssr: false
});
const App: FC<AppProps> = ({
  Component,
  pageProps
}) => {
  const {
    title
  } = useContext(AppContext);
  const production = process.env.NODE_ENV === 'production';
  useEffect(() => {
    if (production) {
      Gleap.initialize('2fIWl1ctLszhV0uux40dJL7oFJkJG9JB');
      setupSentry();
    }
  }, []);
  const MIXPANEL_TOKEN = process.env.MIXPANEL_API || 'FAKE_TOKEN_VALUE'; // Empty string won't work as fake token value

  useEffect(() => {
    const config = production ? {
      debug: false
    } : {
      debug: true
    };
    mixpanel.init(MIXPANEL_TOKEN, config);
  }, []);
  useEffect(monkeyPatchXMLHttpRequest, []);

  // Helper method to rapidly 'spoof' being logged in as a user, if necessary
  useEffect(() => {
    window.mergeAuth = mergeAuth;
  }, []);
  useEffect(() => {
    if (window?.AppcuesSettings) {
      window.AppcuesSettings = {
        enableURLDetection: true
      };
    }
  }, []);

  /* When switching logins via the login flow or an external link, reload any instances
     that did not initiate the change in LocalStorage (native behavior of the Web API)
     Navigate back to the root as the current page may not be valid for the new credentials
  */
  const reloadOnAuthChange = (e: StorageEvent) => {
    if (e.key !== AUTH_STORAGE_KEY) return;
    window.location.href = '/';
  };
  useEffect(() => {
    window.addEventListener('storage', reloadOnAuthChange);
    return () => window.removeEventListener('storage', reloadOnAuthChange);
  }, []);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const suppressLayout = (() => {
    // Suppress for /guides/:id, but not /manage/guides/:id
    if (!pathname?.match(/\/manage\/guides/) && pathname?.match(/\/guides/) && searchParams.get('id') || pathname?.match(/\/results/)) return true;
    return false;
  })();
  const SuppressableLayout = suppressLayout ? Fragment : MainLayout;
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <title>{title || 'Arcade'}</title>
        <link rel="icon" href="/images/favicon.ico" />
      </Head>
      <Script src="https://fast.appcues.com/206255.js"
    // loads the script just before any next.js page is rendered or hydration occurs
    strategy="beforeInteractive" data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
      <Sentry.ErrorBoundary
    /*
    Providing our custom CbErrorBoundary component as the UI for Sentry's error boundary.
    This allows the customer to easily reload the page.
    */ fallback={({
      error
    }) => <CbFallbackUI error={error} />} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
        <MPAuthProvider data-sentry-element="MPAuthProvider" data-sentry-source-file="_app.tsx">
          <SWRConfig value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          fetcher: url => fetch(url).then(res => res.json())
        }} data-sentry-element="SWRConfig" data-sentry-source-file="_app.tsx">
            <SpectatorViewProvider data-sentry-element="SpectatorViewProvider" data-sentry-source-file="_app.tsx">
              <FeaturesProvider data-sentry-element="FeaturesProvider" data-sentry-source-file="_app.tsx">
                <RoleProvider data-sentry-element="RoleProvider" data-sentry-source-file="_app.tsx">
                  <SuppressableLayout data-sentry-element="SuppressableLayout" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                  </SuppressableLayout>
                </RoleProvider>
              </FeaturesProvider>
            </SpectatorViewProvider>
          </SWRConfig>
        </MPAuthProvider>
      </Sentry.ErrorBoundary>
    </>;
};
const AppWithContext: FC<AppProps> = props => {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', maybeReloadOnNavigate);
    return () => router.events.off('routeChangeComplete', maybeReloadOnNavigate);
  }, []);
  useEffect(() => {
    const handleRouteChange = url => {
      const {
        normalizedUrl,
        params
      } = normalizeUrlAndExtractParams(url);
      mixpanel.track('Page Viewed', {
        page: normalizedUrl,
        ...params // Include extracted parameters as properties
      });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // For rendered cards, ignore all wrappers and context, just show the card
  if (router.pathname.startsWith(Routes.RenderedCards)) {
    return <MPThemeContextProvider>
        <props.Component {...props.pageProps} />
      </MPThemeContextProvider>;
  }
  return <StrictMode data-sentry-element="StrictMode" data-sentry-component="AppWithContext" data-sentry-source-file="_app.tsx">
      <MPThemeContextProvider data-sentry-element="MPThemeContextProvider" data-sentry-source-file="_app.tsx">
        <MPAppContextProvider data-sentry-element="MPAppContextProvider" data-sentry-source-file="_app.tsx">
          <App {...props} data-sentry-element="App" data-sentry-source-file="_app.tsx" />
        </MPAppContextProvider>
      </MPThemeContextProvider>
    </StrictMode>;
};
export default AppWithContext;